.Post{
    font-family: Quicksand;
    text-align: Left;
    padding: 10px;
    padding-top: 0px;
    margin: 10px;
    border-radius: 20px;
    border: solid;
    border-width: thin;
    border-color: black;
}

.Post h1{
    text-align: center;
}

.Post h2{
    text-align: center;
}

.Post p{
    font-family: 'NotoSansMono';
}