.Title-Block{
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
  
.Title {
  padding-left: 2.2vmin;
  font-size: 25vmin;
  user-select: none;
}

.Divider {
  background-color: black;
  height: 3vmin;
  width: 80vmin;
}

.Tagline {
  padding-left: 3.2vmin;
  font-size: 3vmin;
  user-select: none;
  width: 76vmin;
}