@font-face {
  font-family: 'NotoSansMono';
  src: local('NotoSansMono'), url(./fonts/NotoSansMono.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url(./fonts/Quicksand.ttf) format('truetype');
}

.App {
}

.App-header {
  font-family: 'NotoSansMono';
  height: 5vh;
}

.App-Title-Page {
  font-family: 'Quicksand';
  Display: grid;
  grid-template-columns: repeat(auto-fit, minmax(85vmin, 1fr));
  height: 95vh;
}

.App-Title-Page-L {
  height: 100%;
}

.App-Title-Page-R {
  vertical-align: middle;
  height: 100%;
}

.App-Title-Page-Bottomtext{
  text-align: center;
  position: absolute;
  bottom: 1.5em;
  margin: 0px;
  height: 1em;
  width: 100%;
}